/** @jsx jsx */
import { css, jsx, useThemeUI } from 'theme-ui'
import Glider from 'react-glider-carousel'
import { ContentfulModule } from '@h/modules'
import { Icon } from '@e/icon'
import { Box } from '@e/box'
import { Palette } from '@uswitch/trustyle-utils.palette'

import { darken } from '@theme-ui/color'

import 'glider-js/glider.min.css'

export default ({
  entries = [],
  sys,
  colsMobile,
  colsTablet,
  colsDesktop,
  carouselControls = false,
  useContainer = false,
  options = {},
  children,
}) => {
  const { theme } = useThemeUI()
  const { styles: optionStyles = {} } = options

  const ITEMS_PER_PAGE = [colsMobile, colsTablet, colsDesktop]

  const baseClass = `cc-${sys.id}`
  const leftClass = `${baseClass}--left`
  const rightClass = `${baseClass}--right`

  const controlCss = css({
    transition: 'fill 0.1s ease, opacity 0.1s ease',
    '&.disabled, &.palette.disabled': {
      fill: 'brand',
      cursor: 'initial',
      opacity: '0.2',
    },
    '&:hover': {
      fill: 'primary',
      cursor: 'pointer',
    },
  })(theme)

  return (
    <Palette
      as='div'
      px={{
        bg: 'backgroundColor',
      }}
    >
      <Palette
        as='div'
        sx={{
          '.glider-dots': { mt: '50px' },
          variant: useContainer
            ? 'modules.collectionCarousel.container'
            : 'modules.collectionCarousel.base',
          ...optionStyles,
        }}
        px={{
          '::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            width: '20px',
            background: palette =>
              `linear-gradient(to right,${palette.backgroundColor},rgba(255,255,255,0))`,
            zIndex: 1,
          },
          '::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            width: '20px',
            background: palette =>
              `linear-gradient(to left,${palette.backgroundColor},rgba(255,255,255,0))`,
            zIndex: 1,
          },
        }}
      >
        <Glider
          hasArrows={carouselControls}
          arrows={{
            prev: `.${leftClass}`,
            next: `.${rightClass}`,
          }}
          hasDots={true}
          settings={{
            scrollLock: true,
            draggable: true,
            slidesToShow: colsMobile,
            responsive: ITEMS_PER_PAGE.slice(1).map((count, i) => ({
              breakpoint: parseInt(theme.breakpoints[i]),
              settings: {
                slidesToShow: count,
                slidesToScroll: count,
              },
            })),
          }}
        >
          {/* TODO: We should add margin Right here, rather than in
           * the card, the margin right also needs to be added
           * programatically based on breakpoints, see
           * card-award-winner.jsx */}

          {children || (
            <For index='key' each='entry' of={entries}>
              <div key={key} sx={{ variant: 'modules.collectionCarousel.item' }}>
                <ContentfulModule props={{ entity: entry }} />
              </div>
            </For>
          )}
        </Glider>

        {carouselControls && (
          <Box
            className='carousel__controls'
            cols={[0, 2, 2]}
            sx={{
              display: ITEMS_PER_PAGE.map((count, i) =>
                entries.length <= count || i === 0 ? 'none' : 'inline-flex',
              ),
              alignItems: 'center',
              flexDirection: ['none', 'column-reverse', 'row'],
            }}
          >
            <Palette
              as={Icon}
              className={leftClass}
              name='chevron-down'
              size='lg'
              sx={{
                ...controlCss,
                transform: 'rotate(90deg)',
              }}
              px={{
                fill: 'accentColor',
                '&:hover': {
                  fill: palette => (palette.accentColor ? darken(palette.accentColor, 0.1)() : ''),
                },
              }}
            />
            <Palette
              as={Icon}
              className={rightClass}
              name='chevron-up'
              size='lg'
              sx={{
                ...controlCss,
                transform: 'rotate(90deg)',
              }}
              px={{
                fill: 'accentColor',
                '&:hover': {
                  fill: palette => (palette.accentColor ? darken(palette.accentColor, 0.1)() : ''),
                },
              }}
            />
          </Box>
        )}
      </Palette>
    </Palette>
  )
}
