/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Button } from '@uswitch/trustyle.button'
import Modal from 'eevee-components/modal'
import { ModulesToReactComponents } from '@h/modules'
import { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'

export default ({ text, modalContent, ...props }) => {
  const [openModal, setOpenModal] = useState(false)
  const [bodyElement, setBodyElement] = useState(null)

  useEffect(() => {
    setBodyElement(document.querySelector('body'))
  }, [])

  const toggleModal = () => setOpenModal(!openModal)

  const ModalComponent = (
    <Modal variant='buttonModal' isOpen={openModal} close={toggleModal}>
      {ModulesToReactComponents(modalContent.content)}
    </Modal>
  )

  return (
    <div>
      <If condition={bodyElement}>{ReactDOM.createPortal(ModalComponent, bodyElement)}</If>
      <Button onClick={toggleModal} {...props}>
        {text}
      </Button>
    </div>
  )
}
