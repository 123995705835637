/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Col, Row } from '@uswitch/trustyle.flex-grid'
import AwardsTag from '@uswitch/trustyle.awards-tag'
import { Button } from '@uswitch/trustyle.button'
import ImgixImg from '@e/imgix-img.connected'

export default ({ logo, description, ratingImage, award, linkHref }) => {
  return (
    <Row cols={12} direction='row' wrap sx={{ variant: 'compounds.supplierRow' }}>
      <Col span={[5, 5, 2]}>
        <ImgixImg entity={logo} width='154px' />
      </Col>
      <Col span={[7, 7, 6]}>
        <p sx={{ variant: 'compounds.supplierRow.description' }}>{description}</p>
      </Col>
      <Col span={[12, 12, 4]}>
        <div sx={{ variant: 'compounds.supplierRow.compare' }}>
          <div>
            <p>Customer Rating</p>
            <ImgixImg entity={ratingImage} width='132px' />
          </div>

          <div>
            <Button variant='secondary' size='large' onClick={() => (window.location = linkHref)}>
              Compare now
            </Button>
          </div>
        </div>
        {award && <AwardsTag award={award} sx={{ variant: 'compounds.supplierRow.award' }} />}
      </Col>
    </Row>
  )
}
