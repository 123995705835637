/** @jsx jsx */
import { jsx, useThemeUI, Styled } from 'theme-ui'
import { lighten, darken } from '@theme-ui/color'

import { Trophy, Medal } from '@a/icons'

import { Icon } from '@e/icon'
import { Link } from '@e/link'
import { ProviderImage } from '@e/imgix-img.connected'
import { Palette } from '@uswitch/trustyle-utils.palette'

import sw from '@u/switch'
import composeVariants from '@u/styled-variants'
import { toKebabCase } from '@u/string'

const isInverted = sw({
  Standard: false,
  Inverted: true,
})

export default ({ title, winner, type, quote, mode, actionText, actionUrl, className }) => {
  const { theme } = useThemeUI()

  return (
    <Palette
      as='div'
      id={toKebabCase(title)}
      sx={{
        position: 'relative',
        ...composeVariants(['compounds.card.award', 'compounds.card.winner'], theme),
      }}
      px={{
        borderTopColor: isInverted(mode) ? 'featureColor' : 'accentColor',
      }}
      className={className}
    >
      <div sx={{ px: 'sm' }}>
        <Palette
          as={isInverted(mode) ? Trophy : Medal}
          sx={{
            position: 'absolute',
            right: 'sm',
          }}
          px={{
            g: { fill: isInverted(mode) ? 'featureColor' : 'accentColor' },
          }}
        />

        <div sx={{ width: '100%', height: '100%', position: 'relative' }}>
          <div sx={{ variant: 'elements.logo' }}>
            <If condition={quote}>
              <ProviderImage entity={winner} />
            </If>
          </div>

          <Styled.h4
            sx={{
              height: '100%',
              fontSize: ['md', 'lg'],
              minHeight: ({ fontSizes }) => [
                fontSizes.md * 1.25, // TODO: Nice to pull this out as a helper
                fontSizes.lg * 2 * 1.25, // 1.25 is the 'em' line height
              ],
              marginY: isInverted(mode) ? ['md', 0, 0] : 0,
            }}
          >
            {title}
          </Styled.h4>
        </div>

        <Choose>
          <When condition={!!quote}>
            <Palette
              as={Styled.p}
              sx={{
                marginTop: ['xs', 'xs', 'md'],
                marginRight: 'md',
                padding: 'xs',
                lineHeight: '1.25em',
                fontSize: ['sm', 'md'],
                fontWeight: 'medium',
                wordBreak: 'break-word',
                borderRadius: 'sm',
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: 'text',
              }}
              px={{
                borderColor: 'featureColor',
                color: p => darken(p.featureColor, 0.3)(),
                backgroundColor: p => lighten(p.featureColor, 0.32)(),
              }}
            >
              {quote}
            </Palette>
          </When>
          <Otherwise>
            <div
              sx={{
                top: 0,
                bottom: 0,
                position: 'absolute',
                marginY: 'auto',
                paddingTop: 'md',
                variant: 'elements.logo',
              }}
            >
              <ProviderImage entity={winner} />
            </div>
          </Otherwise>
        </Choose>

        <div
          sx={{
            position: 'absolute',
            bottom: 'md',
            display: 'block',
          }}
        >
          <Palette
            as='p'
            px={{
              color: 'featureColor',
            }}
            sx={{
              display: 'inline-block',
              marginTop: 'md',
              marginBottom: 'sm',
              lineHeight: '1.25em',
              fontSize: 'xs',
              fontWeight: 'medium',
            }}
          >
            {type}
          </Palette>
          <div
            sx={{
              borderBottomWidth: 2,
              borderBottomStyle: 'solid',
              borderBottomColor: 'black',
            }}
          >
            <Link
              href={actionUrl}
              sx={{
                textDecoration: 'none',
                lineHeight: '2em',
                fontSize: 'base',
                fontWeight: 'medium',
              }}
            >
              {actionText}{' '}
              <Icon sx={{ marginLeft: 'xxs', marginBottom: -2 }} name='arrow-right' size='sm' />
            </Link>
          </div>
        </div>
      </div>
    </Palette>
  )
}
