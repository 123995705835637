/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { path } from '@u/obj'

export const Box = ({ cols = [], className, children, ...props }) => {
  const { theme } = useThemeUI()
  const { lg = {}, md = {}, sm = {} } = path(['sizes', 'grid'], theme, {})

  const [mcSm, mcMd, mcLg] = [sm, md, lg].map(i => Object.keys(i)).map(i => Math.max(1, ...i))
  const [cSm = mcSm, cMd = mcMd, cLg = mcLg] = cols.map(i => +i)

  const [brkMob, brkTab] = path(['breakpoints'], theme, [])
  const gw = path(['space', 'gutter'], theme, 20)

  return (
    <div
      sx={{
        '& + *': {
          ml: 'gutter',
        },
        display: 'inline-block',
        maxWidth: [cSm === 0 ? 0 : brkMob, cMd === 0 ? 0 : brkTab, cLg === 0 ? 0 : `grid.lg.12`],
        width: [
          `calc((100% - (${gw}px * ${mcSm - 1})) / ${mcSm} * ${cSm} + (${gw}px * ${cSm - 1}))`,
          `calc((100% - (${gw}px * ${mcMd - 1})) / ${mcMd} * ${cMd} + (${gw}px * ${cMd - 1}))`,
          `calc((100% - (${gw}px * ${mcLg - 1})) / ${mcLg} * ${cLg} + (${gw}px * ${cLg - 1}))`,
        ],
      }}
      className={className}
      {...props}
    >
      {children}
    </div>
  )
}

export default Box
